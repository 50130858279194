<template>
  <table>
    <tr class="top">
      <td colspan="2">
        <table>
          <tr>
            <td class="title">
              <img alt="" src="../../../assets/images/logo.svg" style="width:300px;">
            </td>
            <td>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr class="item" style="text-align: justify">
      <td colspan="2">
        {{ text }}
      </td>
    </tr>
    <tr style="height: 3vw;">
      <td></td>
      <td></td>
    </tr>
    <!--        --------------------------------------------------     OPCIONES DE HARDWARE-->
    <tr class="heading" v-if="arrayHardware">
      <td>HARDWARE REQUIRED MODS</td>
      <td></td>
    </tr>
    <tr v-if="arrayHardware" class="" v-for="(item) in arrayHardware">
      <td style="padding-top: 0 !important; padding-bottom: 0">{{ item.name }}</td>
      <td style="padding-top: 0 !important; padding-bottom: 0">
        <span v-if="item.options.length>0" style="color:black;"> {{ item.options[0].description }}</span>
      </td>
    </tr>
    <!--          --------------------------------------------------      OPCIONES DE SOFTWARE-->
    <tr class="heading" v-if="arraySoftware">
      <td>ECU PRE-INCLUDED OPTIONS</td>
      <td></td>
    </tr>
    <tr v-if="arraySoftware" class="" v-for="(item) in arraySoftware">
      <td style="padding-top: 0 !important; padding-bottom: 0">{{ item.name }}</td>
      <td style="padding-top: 0 !important; padding-bottom: 0">
        <span v-if="item.options.length>0" style="color:black;"> {{ item.options[0].description }}</span>
      </td>
    </tr>
    <!--     --------------------------------------------------      OPCIONES DE DSG-->
    <tr class="heading" v-if="arrayDsg">
      <td>DSG PRE-INCLUDED OPTIONS</td>
      <td></td>
    </tr>
    <tr v-if="arrayDsg" class="" v-for="(item) in arrayDsg">
      <td style="padding-top: 0 !important; padding-bottom: 0">{{ item.name }}</td>
      <td style="padding-top: 0 !important; padding-bottom: 0">
        <span v-if="item.options.length>0" style="color:black;"> {{ item.options[0].description }}</span>
      </td>
    </tr>
  </table>
</template>

<script>


import {arrayEcu, arrayDsg, arrayCombos} from '@/helpers/legends.js';
import {comboImages} from '@/views/dashboard/create-software-order/proccessCombos';

export default {
  name: 'TableOrder',
  props: ['infoDsg', 'infoSoftware', 'infoHardware', 'infoType', 'infoStage'],
  computed: {
    arrayDsg() {
      return this.infoDsg;
    },
    arraySoftware() {
      return this.infoSoftware;
    },
    arrayHardware() {
      return this.infoHardware;
    },
    orderType() {
      return this.infoType;
    },

    orderStage() {
      return this.infoStage;
    },
  },
  watch: {
    arrayDsg: {
      immediate: true,
      handler() {
      }
    },
    arraySoftware: {
      immediate: true,
      handler() {
      }
    },
    arrayHardware: {
      immediate: true,
      handler() {
      }
    },

    orderType: {
      immediate: true,
      handler(t) {

      }
    },

    orderStage: {
      immediate: true,
      handler(t) {

      }
    },
  },
  data: () => ({
    text: null,
  }),
  mounted() {
    this.filterType();
  },
  methods: {
    filterType() {
      if (this.orderType.toString() === 'ECU') {
        const temp = arrayEcu.find(x =>  x.type === this.orderStage);
        if (!!temp) {
          this.text = temp.description;
        }
      } else if (this.orderType.toString() === 'DSG') {
        const temp = arrayDsg.find(x => x.type == this.orderStage);
        if (!!temp) {
          this.text = temp.description;
        }
      }
    }
  },
};
</script>

<style scoped lang="scss">
@media print {
  * {
    margin: 0;
    padding: 0
  }
  @page {
    size: A4 portrait;
    margin: 0mm;
    page-break-after: always;
  }
  html, body {
    height: 100% !important;
  }
  //@-moz-document url-prefix() {
  //  html,
  //  body {
  //    height: 120vh !important;
  //    margin-top: 0 !important;
  //    padding-top: 0 !important;
  //    page-break-after: always;
  //  }
  //
  //}
}
</style>

