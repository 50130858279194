<template>
  <b-modal title="Change Vehicle and/or Stage" v-model="active" @ok="save" size="lg">
    <b-row>
      <b-col lg="6">
        <div class="form-group">
          <label>Brand</label>
          <select id="brand" v-model="filter_brand" class="form-control selector"
                  name="brand">
            <option value="null">Select Brand</option>
            <option v-for="(item, index) in brands" :value="item.make" :key="index">{{ item.make }}</option>
          </select>
        </div>
      </b-col>
      <b-col lg="6">
        <div class="form-group">
          <label>Model</label>
          <select id="model" v-model="filter_model" class="form-control selector" name="model">
            <option :value="null">Select Model</option>
            <option v-for="(item, index) in models" :key="index" :value="item.model">{{ item.model }}</option>
          </select>
        </div>
      </b-col>
      <b-col lg="6">
        <div class="form-group">
          <label>Generation</label>
          <select id="generation" v-model="filter_generation" class="form-control selector" name="generation">
            <option :value="null">Select Generation</option>
            <option v-for="(item, index) in generations" :value="item.generation" :key="index">{{
                item.generation
              }}
            </option>
          </select>
        </div>
      </b-col>
      <b-col lg="6">
        <div class="form-group">
          <label>Engine</label>
          <select id="engine" v-model="filter_engine" class="form-control selector" name="engine">
            <option :value="null">Select Engine</option>
            <option v-for="(item, index) in engines" :value="item.engine" :key="index">{{ item.engine }}</option>
          </select>
        </div>
      </b-col>
      <b-col>
        <div class="form-group" v-show="arrayGearbox.length">
          <label>DSG</label>
          <select id="gearbox" v-model="filter_gearbox" class="form-control selector" name="gearbox">
            <option :value="null">Select DSG</option>
            <option v-for="(item, index) in arrayGearbox" :value="item.value" :key="index">
              {{ item.text }}
            </option>
          </select>
        </div>
      </b-col>
    </b-row>
    <div class="form-group">
      <label>Comment</label>
      <b-textarea v-model="comment" placeholder="(Optional)"></b-textarea>
    </div>
    <hr>
    <b-row>
      <b-col>
        <h4 class="text-center text-black-50">ECU</h4>
        <div v-for="item of sofwareTunning">
          <b-button @click="setStage(item)" class="my-2" block :variant="variant(item)">
            Stage {{ item.id.replace('.1', '+') }}
            <span class="font-weight-bold" v-if="item.price < 9999">€ {{ item.price }}</span>
            <span class="font-weight-bold" v-else>On request</span>
          </b-button>
        </div>
      </b-col>
      <b-col v-if="combosTunning">
        <h4 class="text-center text-black-50">ECU + DSG</h4>
        <div v-for="item of combosTunning.combos">
          <b-button @click="setStage(item)" class="my-2" block
                    :variant="variant(item)">
            ECU {{ item.ecu.toString().replace('.1', '+') }} (+) DSG {{ item.dsg.toString().replace('.1', '+') }}
            <span class="font-weight-bold" v-if="item.price < 9999">€ {{ item.price }}</span>
            <span class="font-weight-bold" v-else>On request</span>
          </b-button>
        </div>
      </b-col>
      <b-col>
        <h4 class="text-center text-black-50">DSG</h4>
        <div v-for="item of dsgTunning">
          <b-button @click="setStage(item)" class="my-2" block :variant="variant(item)">
            {{ isNaN(item.id) ? '' : 'Stage' }} {{ item.id.replace('.1', '+') | orderType }}
            <span class="font-weight-bold" v-if="item.price < 9999">€ {{ item.price }}</span>
            <span class="font-weight-bold" v-else>On request</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <div class="form-group">
      <label>Comment</label>
      <b-textarea v-model="comment_stage" placeholder="(Optional)"></b-textarea>
    </div>
    <hr>
    <div class="text-center mb-3">
      <strong>Note: only vehicle and/or stage would change , not the options.</strong>
    </div>
  </b-modal>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {post} from "@/services/api";

export default {
  name: "ChangeVehicle",
  data: () => ({
    active: false,
    order: null,
    params: null,
    previous_params: null,
    previous_vehicle: null,
    comment: '',
    comment_stage: '',
    selectedStage: {
      price: null,
      old_level: null,
      new_level: null,
    }
  }),
  computed: {
    ...mapState('orderSoftware', ['brands', 'models', 'engines', 'generations', 'filters', 'combosTunning', 'dsgTunning', 'sofwareTunning', 'manualSearch', 'cart', 'autoSearch', 'arrayGearbox']),
    filter_brand: {
      get() {
        return this.$store.state.orderSoftware.filters.brand;
      },
      set(value) {
        this.optionPreSelected = null;
        this.showOptions = false;
        this.$store.commit('orderSoftware/updateFilter', {
          key: 'brand',
          value
        });
        this.$store.dispatch('orderSoftware/load', 'models');
        this.showDsgEngineSection = false
      }
    },
    filter_model: {
      get() {
        return this.$store.state.orderSoftware.filters.model;
      },
      set(value) {
        this.optionPreSelected = null;
        this.showOptions = false;
        this.$store.commit('orderSoftware/updateFilter', {
          key: 'model',
          value
        });
        this.$store.dispatch('orderSoftware/load', 'generations');
        this.showDsgEngineSection = false
      }
    },
    filter_generation: {
      get() {
        return this.$store.state.orderSoftware.filters.generation;
      },
      set(value) {
        this.optionPreSelected = null;
        this.showOptions = false;
        this.$store.commit('orderSoftware/updateFilter', {
          key: 'generation',
          value
        });
        this.$store.dispatch('orderSoftware/load', 'engines');
        this.showDsgEngineSection = false
      }
    },
    filter_engine: {
      get() {
        return this.$store.state.orderSoftware.filters.engine;
      },
      set(value) {
        this.optionPreSelected = null;
        this.showOptions = false;
        if (this.engines.length && this.engines[0].generation) {
          this.$store.state.orderSoftware.filters.generation = this.engines.find(el => el.engine == value).generation;
        }
        this.$store.commit('orderSoftware/updateFilter', {
          key: 'engine',
          value
        });
        this.$store.commit('orderSoftware/clearGearbox')
        this.focused = false;
        this.vehicleOptions(true)
          .then((result) => {
            this.showManualSearch = result;
            this.showDsgEngineSection = true;
          });
      },
    },
    filter_gearbox: {
      get() {
        return this.$store.state.orderSoftware.filters.gearbox
      },
      set(value) {
        this.$store.commit('orderSoftware/updateFilter', {key: 'gearbox', value});
        this.foundgearbox = false;
        if (value) {
          this.vehicleOptions(false)
            .then(() => {
              this.showManualSearch = true;
              this.showDsgEngineSection = false
            });
        } else {
          this.$store.commit('orderSoftware/clearResult')
          this.showDsgEngineSection = false
        }
      }
    },
  },
  methods: {
    ...mapActions('orderSoftware', ['vehicleOptions']),
    open(order) {
      this.previous_vehicle = order.vehicle
      this.$store.dispatch('orderSoftware/loadBrands');
      this.order = {
        id: order.id,
        type: order.type,
        stage: order.stage,
      };
      this.params = this.$store.getters['orderSoftware/getPreorderParams'](this.order);
      this.previous_params = this.$store.getters['orderSoftware/getPreorderParams'](this.order);
      const payload = [
        {key: 'filter_brand', value: this.previous_vehicle.brand},
        {key: 'filter_model', value: this.previous_vehicle.model},
        {key: 'filter_generation', value: this.previous_vehicle.generation},
        {key: 'filter_engine', value: this.previous_vehicle.engine},
        {key: 'filter_gearbox', value: this.previous_vehicle.gearbox}
      ]
      for (let i = 0; i < payload.length; i++) {
        this[payload[i].key] = payload[i].value;
      }
      this.active = true;
    },
    validateStage() {
      if (this.params.type === 'ECU') {
        return this.sofwareTunning.find(el => el.id === this.params.id)
      } else if (this.params.type === 'DSG') {
        if (this.dsgTunning.length) {
          return this.dsgTunning.find(el => el.id === this.params.id)
        }
      } else {
        if (this.combosTunning && this.combosTunning.combos) {
          return this.combosTunning.combos.find(el => el.id === this.params.id)
        }
      }
      return false;
    },
    async save() {
      if (this.$store.state.orderSoftware.cart.vehicleId.id && this.previous_vehicle.id !== this.$store.state.orderSoftware.cart.vehicleId.id) {
        if (this.validateStage()) {
          await post(`order-software/update-vehicle`, {
            id: this.order.id,
            vehicle: this.$store.state.orderSoftware.cart.vehicleId,
            previous_vehicle: this.previous_vehicle,
            comment: this.comment
          }).then(async () => {
            this.$emit('updated', this.$store.state.orderSoftware.cart.vehicleId)
            await this.$swal({
              icon: 'success',
              timer: 2000,
              title: 'Vehicle updated successfully',
              showConfirmButton: false
            })
          })
        } else {
          await this.$swal({
            icon: 'error',
            title: `The new vehicle selected does not have the order stage.`,
            showCancelButton: false,
            confirmButtonColor: "#d14343",
            timer: 5000
          })
        }
      }
      if (this.params.id !== this.previous_params.id || this.previous_params.type !== this.params.type) {
        await post(`order-software/update-stage`, {
          id: this.order.id,
          params: this.order,
          comment: this.comment_stage
        }).then(async () => {
          if (this.selectedStage.price > 0 && this.selectedStage.price !== 9999) {
            await this.$swal({
              icon: 'success',
              confirmButtonColor: "#d14343",
              cancelButtonColor: "#000000",
              confirmButtonText: "OK",
              html: `Stage successfully updated. <br> <br>
                   Please, confirm that you want to update order price to <span class="font-weight-bold">€${this.selectedStage.price}</span> or rather use a different price in the next window.
                   <br><br>
                   <span class="text-danger"> Note: if canceled, order price will keep its current value. </span>`,
              showConfirmButton: true
            }).then(() => {
              this.$emit('updated', {price: this.selectedStage.price, update_price: true})
            })
          } else {
            this.$emit('updated', {price: 0, update_price: true})
          }
        })
      }
    },
    setStage(item) {
      this.order.type = item.type.toUpperCase();
      this.params.type = item.type.toUpperCase();
      this.order.stage = item.id
      this.params.id = item.id;
      this.selectedStage.price = item.price
      this.selectedStage.old_level = this.order.stage
      this.selectedStage.new_level = item.id
    },
    variant(item) {
      if (!!item)
        return item.id === this.params.id && item.type.toUpperCase() === this.params.type ? 'danger' : 'dark'
    }
  }
}
</script>

<style scoped>

</style>
