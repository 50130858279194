<template>
  <b-card-body class="p-0 scroll-content-500">
    <div class="col text-right">
      <b-button variant="outline-danger" title="Download all files" @click="downloadAllFiles">
        <i class="material-icons text-danger">browser_updated</i>
      </b-button>
    </div>
    <div v-for="(item, i) of histories" :key="i" class="user-activity__item pr-3 py-3 position-relative">
      <div class="user-activity__item__icon">
        <i class="material-icons">{{ item.extraData.length ? 'file_present' : 'subject' }}</i>
      </div>
      <div class="user-activity__item__content" style="width: 90%;">
        <span class="text-light">{{ item.createdAt|localeDate }}</span>
        <p>By: {{ item.user.fullName }}</p>
        <p>Message: {{ item.message }}</p>
        <ul v-if="item.extraData.length" class="user-activity__item__task-list mt-2">
          <li>
            <div v-for="(file, i) of item.extraData" :key="i" class="d-flex">
              <div v-if="$userCan(['administrator','calibrator'])" class="btn-download"
                   style="margin-right: 0.5vw;">
                <a v-tippy="{ placement : 'top',  arrow: true }" :content="'Remove file'" role="button" @click="deleteFile(i, item)"><i
                  class="material-icons text-danger">clear</i></a>
              </div>
              <div class="btn-download" style="margin-right: 0.5vw;">
                <a
                  v-tippy="{ placement : 'top',  arrow: true }" :content="file.s3url ?'Download file':'The file is not available yet to download.'"
                  :href="file.s3url" target="_blank">
                  <i :class="[file.s3url?'text-danger' : 'text-gray']"
                     class="material-icons">cloud_download</i>
                </a>
              </div>
              <p class="text-secondary" style="line-height: 2.1875rem">{{ file.name }}</p>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="$userCan(['administrator','calibrator'])" class="rm-item-btn">
        <div v-tippy="{ placement : 'top',  arrow: true }" :content="'Delete history'" class="btn-download">
          <a role="button" @click="deleteHistory(item, i)"><i
            class="material-icons text-danger">clear</i></a>
        </div>
      </div>
    </div>
  </b-card-body>
</template>

<script>
import {post} from "@/services/api";
import {decrypt} from "@/helpers/encrypt";
import {mapMutations} from "vuex";

export default {
  name: "FileHistory",
  props: ['infoHistories', 'order'],
  computed: {
    histories() {
      return this.infoHistories;
    },
  },
  watch: {
    histories: {
      immediate: true,
      handler(loc) {
      }
    },
  },
  data: () => ({
    showOverlay: false
  }),
  methods: {
    ...mapMutations(['loaderManager']),
    deleteFile(fileIndex, item) {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "OK",
        cancelButtonText: "No",
        title: 'Are you sure?'
      })
        .then(res => {
          if (res.isConfirmed) {
            this.loaderManager(true);
            let file = item.extraData[fileIndex];
            const viewed = JSON.stringify(this.processViewedInformation())
            post('order-software/rm-file', {
              ms_id: item.id,
              uuid: file.uuid,
              key: file.key,
              viewed: viewed
            }).then(res => {
              item.extraData.splice(fileIndex, 1);
              this.loaderManager(false);
            });
          }
        });

    },
    deleteHistory(item, index) {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "OK",
        cancelButtonText: "No",
        title: 'Are you sure?'
      })
        .then(res => {
          if (res.isConfirmed) {
            this.loaderManager(true);
            const viewed = JSON.stringify(this.processViewedInformation())
            post('order-software/rm-history', {
              ms_id: item.id,
              viewed: viewed
            })
              .then(res => {
                this.histories.splice(index, 1);
                this.loaderManager(false);
              });
          }
        });
    },
    processViewedInformation() {
      const store = localStorage.getItem("admin-type");
      const sessionRol = JSON.parse(decrypt(store))
      const obj = {
        id: 1,
        administrator: 1,
        calibrator: 1,
        distributor: 1,
        dealer: 1,
        subdealer: 1,
        owner: 1,
      }
      obj[sessionRol[0]] = 0
      return obj
    },
    async downloadAllFiles() {
      this.loaderManager(true);
      try {
        const {data} = await post("order-software/download-all-files", {
          id: this.order.id,
          orderId: this.order.orderId
        }, true)
        window.open(data)
        this.loaderManager(false);
      } catch (e) {
        this.loaderManager(false);
      }
    }
  }
}
</script>

<style scoped>

</style>
