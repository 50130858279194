<template>
  <div>
    <b-row no-gutters>
      <b-col
        v-if="completeStatus && (completeStatus.is_tcu_completed && completeStatus.is_ecu_completed)"
        lg="5">
        <div class="status" :style="`background: ${getColor(order.status)}`">
          {{ order.status|orderStatus }}
        </div>
        <b-row class="mb-2">
          <b-col>
            <div @mouseenter="show()"
                 style="cursor: pointer">
              <div ref="title" class="user-activity__item__icon custom"
                   v-tippy="{
                    placement : 'bottom-start',
                    arrow: true,
                    showOnInit: true,
                    size: 'large',
                    delay:500, duration: 1000,
                    onShow: () => (!this.completeStatus.is_owner_completed && order.dealerId == $store.getters.getUserId && (!['Warranty','Rejected','Cancel'].includes(order.status)) && can_client_complete_order)
                   }"
                   :content='`Please click here to complete the order`'
                   @click="dealerComplete">
                <i class="material-icons" v-if="completeStatus.is_owner_completed">done</i>
              </div>
              <span>Dealer completed</span>
            </div>
          </b-col>
          <b-col>
            <div @click="tvsComplete" style="cursor: pointer">
              <div class="user-activity__item__icon custom">
                <i class="material-icons"
                   v-if="completeStatus.is_tvs_completed || order.status === 'Completed'">done</i>
              </div>
              <span>
              TVS completed
            </span>
            </div>
          </b-col>
        </b-row>
        <b-list-group>
          <b-list-group-item class="d-flex justify-content-between p-1">
            <span>Order date</span>
            <span>{{ order.createdAt|localDateShort }}</span>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between p-1">
            <span>Tune uploaded date</span>
            <span>{{ completeStatus.tune_supplied_date|localDateShort }}</span>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between p-1">
            <span>Dealer completed date</span>
            <span>{{ completeStatus.dealer_completed_date|localDateShort }}</span>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between p-1">
            <span>TVS completed date</span>
            <span>{{ completeStatus.tvs_completed_date|localDateShort }}</span>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between p-1">
            <span>Order finished date</span>
            <span>{{ completeStatus.order_finished_date|localDateShort }}</span>
          </b-list-group-item>
        </b-list-group>
        <b-form-group class="mt-3"
                      v-if="order.dealerId == $store.getters.getUserId && !completeStatus.is_owner_completed"
                      :disabled="['Warranty','Rejected','Cancel'].includes(order.status) || !can_client_complete_order">
          <b-button variant="danger" size="lg" block @click="dealerComplete">COMPLETE ORDER</b-button>
        </b-form-group>
        <b-form-group class="mt-3">
          <b-form-textarea v-model="completeStatus.comment" rows="8" placeholder="Final comment"
                           :disabled="$userCan(['dealer','subdealer','distributor'])"/>
          <div class="row mt-2" v-if="$userCan(['administrator','calibrator','support'])">
            <div class="col text-center">
              <b-button class="btn-outline btn-block" @click="saveComment" variant="danger">
                Save Comment
              </b-button>
            </div>
          </div>
          <hr>
          <div class="row mt-2" v-if="$userCan(['administrator','calibrator','support'])">
            <div class="col">
              <b-form-group>
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="client_can_complete"
                  name="checkbox-1"
                  :value="true"
                  :unchecked-value="false"
                >
                  <span class="font-weight-bold">Disable ''Dealer Complete'' function</span>.
                </b-form-checkbox>
                <p>
                  Current status :
                  {{ can_client_complete_order ? 'Dealer can complete order' : 'Dealer can NOT complete order' }}</p>
                <hr class="my-1">
                <b-button class="btn-outline btn-block" @click="clientCanCompleteOrder" variant="dark">
                  Update permission
                </b-button>
              </b-form-group>
            </div>
          </div>
        </b-form-group>
      </b-col>
      <b-col>
        <div class="user-activity__item py-3" v-for="(item, index) in logs">
          <div class="user-activity__item__icon">
            <i class="material-icons">&#xE5CA;</i>
          </div>
          <div class="user-activity__item__content" style="width: 90%;">

            <span class="text-light"> {{ item.createdAt | localeDate }} </span>
            <p style="white-space: break-spaces;">{{ item.description.text }}</p>
            <small>{{ item.user.fullName }}</small><br>
            <template v-if="item.description.oldItems && item.description.oldItems.length">
              <span style="color: #3D5170; font-weight: 400" v-b-toggle="`collapse_${index}`">see more ...</span>
              <b-collapse :id="`collapse_${index}`">
                <div class="row">
                  <div class="col-6 col-md-6 col-sm-12 col-xs-12">
                    <h6>Previous options</h6>
                    <div class="table-responsive">
                      <table class="table table-bordered table-condensed table-hover table-sm">
                        <tr>
                          <th>
                            Options
                          </th>
                          <th style="justify-content: center">
                            Extra info
                          </th>
                        </tr>
                        <tr v-for="(item) in item.description.oldItems" class="item">
                          <td>
                            {{ item.name }}
                          </td>
                          <td>
                            <ul style="list-style: none;">
                              <li v-for="value in item.options">{{ value.description }}</li>
                            </ul>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-6 col-md-6 col-sm-12 col-xs-12">
                    <h6>New Options</h6>
                    <div class="table-responsive">
                      <table class="table table-bordered table-condensed table-hover table-sm">
                        <tr>
                          <th>
                            Options
                          </th>
                          <th>
                            Extra info
                          </th>
                        </tr>
                        <tr v-for="(item) in item.description.newItems" class="item">
                          <td>
                            {{ item.name }}
                          </td>
                          <td>
                            <ul style="list-style: none;">
                              <li v-for="value in item.options">{{ value.description }}</li>
                            </ul>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </template>
            <template v-if="item.description.previous_vehicle">
              <span style="color: #3D5170; font-weight: 400" v-b-toggle="`collapse_${index}`">see more ...</span>
              <b-collapse :id="`collapse_${index}`">
                <div class="row">
                  <div class="col-6 col-md-6 col-sm-12 col-xs-12">
                    <h6>Previous vehicle</h6>
                    <div class="table-responsive">
                      <table class="table table-bordered table-condensed table-hover table-sm">
                        <tr v-for="(key) of vehicle_fields" class="item">
                          <td>
                            {{ key }}
                          </td>
                          <td>
                            {{ item.description.previous_vehicle[key] }}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-6 col-md-6 col-sm-12 col-xs-12">
                    <h6>New Vehicle</h6>
                    <div class="table-responsive">
                      <table class="table table-bordered table-condensed table-hover table-sm">
                        <tr v-for="(key) of vehicle_fields" class="item">
                          <td>
                            {{ key }}
                          </td>
                          <td>
                            {{ item.description.new_vehicle[key] }}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </template>
            <template v-if="item.description.previous_stage">
              <span style="color: #3D5170; font-weight: 400" v-b-toggle="`collapse_${index}`">see more ...</span>
              <b-collapse :id="`collapse_${index}`">
                <div class="row">
                  <div class="col-6 col-md-6 col-sm-12 col-xs-12">
                    <h6>Previous Stage</h6>
                    <div class="table-responsive">
                      <table class="table table-bordered table-condensed table-hover table-sm">
                        <tr v-for="(key) of Object.keys(item.description.previous_stage)" class="item">
                          <td>
                            {{ key }}
                          </td>
                          <td>
                            {{ item.description.previous_stage[key] }}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-6 col-md-6 col-sm-12 col-xs-12">
                    <h6>New Stage</h6>
                    <div class="table-responsive">
                      <table class="table table-bordered table-condensed table-sm table-hover">
                        <tr v-for="(key) of Object.keys(item.description.new_stage)" class="item">
                          <td>
                            {{ key }}
                          </td>
                          <td>
                            {{ item.description.new_stage[key] }}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </template>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {post} from "@/services/api";
import {UserRole} from "@/utils/auth.roles";
import {ORDER_STATUS} from "@/constants";

export default {
  name: 'Logs',
  props: ['infoLogs', 'completeStatus', 'order'],
  data: () => ({
    vehicle_fields: ['brand', 'model', 'generation', 'engine', 'dsg'],
    client_can_complete: true
  }),
  computed: {
    logs() {
      return this.infoLogs;
    },
    can_client_complete_order(){
      return (this.order.description.can_client_complete_order || this.order.description.can_client_complete_order === undefined)
    }
  },
  methods: {
    show() {
      this.$refs.title.dispatchEvent(new MouseEvent('mouseenter'))
    },
    getColor(status) {
      const obj = ORDER_STATUS().find(x => x.value === status)
      return obj ? obj.color : 'black'
    },
    dealerComplete() {
      if (['Warranty', 'Rejected', 'Cancel'].includes(this.order.status) || !this.can_client_complete_order) {
        return;
      }
      if (!this.completeStatus.is_owner_completed && this.order.dealerId == this.$store.getters.getUserId) {
        this.$swal({
          text: `Order's credits charge correct, vehicle performing correctly and customer satisfied?`,
          icon: 'question',
          confirmButtonText: 'YES, COMPLETE ORDER',
          denyButtonText: 'NO',
          showDenyButton: true,
          confirmButtonColor: '#d14343',
          denyButtonColor: '#000',
        }).then(({isConfirmed, isDismissed}) => {
          if (!isDismissed) {
            if (isConfirmed) {
              this.sendCompleteToserver()
            } else {
              this.$emit('toSupport')
            }
          }
        })
      }
    },
    tvsComplete() {
      if (['Warranty', 'Rejected', 'Cancel'].includes(this.order.status)) {
        return;
      }
      if (!this.completeStatus.is_tvs_completed && this.$userCan([UserRole.Admin, UserRole.Calibrator, UserRole.Support])) {
        this.$swal({
          text: `Do you want to complete the order and deduct the credits from the Dealer's account?`,
          icon: 'question',
          confirmButtonText: 'YES, COMPLETE ORDER',
          showCancelButton: true,
          confirmButtonColor: '#d14343',
          cancelButtonColor: '#000000'
        }).then(({isConfirmed, isDismissed}) => {
          if (!isDismissed) {
            if (isConfirmed) {
              this.sendCompleteToserver()
            } else {
              this.$emit('toSupport')
            }
          }
        })
      }
    },
    sendCompleteToserver() {
      post(`order-software/complete/${this.order.id}`, {}, true)
        .then(() => {
          this.$emit('reloadPage')
        }).catch(async (err) => {
        await this.$swal({
          title: 'Error',
          html: (this.order.dealerId == this.$store.getters.getUserId) ?
            `Balance insufficient` : `Customer's balance insufficient`,
          icon: 'error',
          showConfirmButton: true,
          confirmButtonColor: '#d14343',
          confirmButtonText: 'OK',
        });
      })
    },
    saveComment() {
      this.$swal({
        text: `Are you sure?`,
        icon: 'question',
        confirmButtonText: 'YES',
        showCancelButton: true,
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000'
      }).then(({isConfirmed, isDismissed}) => {
        if (!isDismissed) {
          if (isConfirmed) {
            post(`order-software/complete-comment/${this.order.id}`, {comment: this.completeStatus.comment}, true)
              .then(() => {
                this.$swal({
                  title: 'Success',
                  icon: 'success',
                  confirmButtonColor: '#d14343',
                  timer: 2500
                })
                this.$emit('reloadPage')
              })
          }
        }
      })
    },
    async clientCanCompleteOrder() {
      await post(`order-software/client-complete-order`, {id: this.order.id, can_client_complete_order: !this.client_can_complete})
      this.$swal({
        title: 'Success',
        icon: 'success',
        confirmButtonColor: '#d14343',
        timer: 2500
      })
      this.$emit('reloadPage')
    }
  },
  mounted() {
    this.client_can_complete = !this.can_client_complete_order
  }
};
</script>

<style scoped lang="scss">
.status {
  margin-bottom: .7rem;
  padding: 0.5vw;
  color: white;
  font-weight: bold;
}

.user-activity__item__icon i {
  line-height: 1.6rem;
}

.user-activity__item__icon.custom {
  background: white;
  margin-left: 0;
  margin-right: 0.3rem;
  //box-shadow: 0 0 0 2px white, inset 0 0 3px rgb(0 0 0 / 75%);

  i {
    font-size: 2.2rem;
    font-weight: bold;
    line-height: 1.2rem;
    color: #3db953;
  }

  + span {
    line-height: 2rem;
  }
}

table {
  tr {
    th {
      background: #eee;
      padding: 2px;
      font-size: .88em;
    }

    border-bottom: 1px solid #dddddd;

    td {
      padding: 2px;
      font-size: .85em;

      ul {
        padding: 0;
      }
    }
  }
}
</style>
