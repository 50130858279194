<template>
  <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 table-responsive" style="display: flex">
    <div class="col-lg-4 col-md-4 col-sm-4">
      <table class="table table-hover table-sm table-bordered">
        <thead>
        <tr>
          <th colspan="2">Chats</th>
        </tr>
        <tr>
          <th>Date</th>
          <th>Option</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in tickets" :key="index">
          <td>{{ item.createdAt | localeDate }}</td>
          <td>
            <a v-tippy="{ placement : 'top',  arrow: true }" :content="'View Detail'" role="button" @click="filterMessage(item.id)"><i
              class="material-icons text-black">visibility</i></a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="col-lg-8 col-md-8 col-sm-8">
      <table class="table table-hover table-sm table-bordered">
        <thead>
        <tr>
          <th colspan="2">Messages</th>
        </tr>
        <tr>
          <th>User</th>
          <th>Message</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item , index) in arrayMessages" :key="index">
          <td>{{ item.rol }}</td>
          <td v-if="item.type === 0">{{ item.text }}</td>
          <td v-if="item.type === 1">
            <a v-tippy="{ placement : 'top',  arrow: true }" :content="'View detail'" role="button" :href="item.text" target="_blank">
              <i class="material-icons text-black">file_download</i> View File
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: "SupportHistory",
  props: ['support', 'currentUserId'],
  computed: {
    ...mapState('buySale', ['information']),
    tickets() {
      return this.support;
    },
    userId() {
      return this.currentUserId;
    },
  },
  watch: {
    tickets: {
      immediate: true,
      handler(loc) {
      }
    },
    userId: {
      immediate: true,
      handler(loc) {
      }
    },
  },
  data: () => ({
    arrayMessages: []
  }),
  methods: {
    filterMessage(id) {
      this.arrayMessages = [];
      const tempMessages = this.tickets.filter(x => x.id === id)[0].supportTicketMessages;
      const copyArray = JSON.parse(JSON.stringify(tempMessages))
      this.arrayMessages = copyArray.map(item => {
        item.type = 0;
        const rol1 = (this.information.rol.includes('distributor') || this.information.rol.includes('dealer') || this.information.rol.includes('subdealer')) ? 'Customer' : 'TVS'
        if (Number(item.userId) === Number(this.userId)) {
          item.rol = rol1
        } else {
          item.rol = (rol1.toString() === 'TVS') ? 'Customer' : 'TVS'
        }
        if (item.text === '' && item.extraData !== null) {
          const data = JSON.parse(item.extraData);
          if (data) {
            item.type = 1;
            item.text = data[0].s3url
          }
        }
        return {type: item.type, text: item.text, rol: item.rol}
      })
    }
  }
}

</script>

<style scoped>

</style>

