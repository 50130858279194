<template>
  <b-modal v-model="active" title="Update price" hide-footer id="modal_update_price">
    <b-overlay :show="loading">
      <div>
        <b-form-group v-if="price_policy === null">
          <label class="required">Price <span>(required)</span></label>
          <b-input :disabled="['Completed','Cancel','Warranty'].includes(order.status)" v-model.number="price"></b-input>
        </b-form-group>
        <div v-if="price_policy" class="mb-3">
          <b-form-group v-if="profitDistributor">
            <label class="required"><strong> TVS Price: €{{profitDistributor.original_price}}</strong></label>
          </b-form-group>
          <b-form-group>
            <label class="required">Distributor Price <span>(required)</span></label>
            <b-input :disabled="['Completed','Cancel','Warranty'].includes(order.status)" v-model.number="price"></b-input>
          </b-form-group>
          <h6>Profit to distributor result</h6>
          <b-list-group>
            <b-list-group-item class="d-flex justify-content-between">
              <strong>Distributor</strong>
              <span>{{ price_policy.distributor.username }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <strong>Percentage profit given to Distributor</strong>
              <b-input class="mx-2" type="range" v-model.number="price_policy.percentage_on_request" min="1" max="2" step="0.01"></b-input>
              <span>{{ ((price_policy.percentage_on_request - 1) * 100).toFixed(0) }}%</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <strong>New order price (price paid by dealer)</strong>
              <span><currency-price-view :amount="final_price"/></span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <strong>TVS will receive</strong>
              <span><currency-price-view :amount="price"/></span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <strong>Distributor profit</strong>
              <span><currency-price-view :amount="final_price - price"/></span>
            </b-list-group-item>

            <b-list-group-item class="d-flex justify-content-between">
              <strong>Distributor upgrade profit - TVS upgrade profit</strong>
              <span> € {{ pricePolicy.distributor.minimum_price -  pricePolicy.tvs.minimum_price}}</span>
            </b-list-group-item>
          </b-list-group>
        </div>
        <b-form-group>
          <label class="required">Enter Comments <span>(required)</span></label>
          <b-form-textarea v-model="commentsUpdatePrice" placeholder="Enter comments" :rows="6" :max-rows="6"/>
        </b-form-group>
        <div class="row mt-2">
          <div class="col">
            <b-button class="btn-block" variant="danger" @click="cancelUpdate()">CANCEL
            </b-button>
          </div>
          <div class="col">
            <b-button :disabled="['Completed','Cancel','Warranty'].includes(order.status)" class="btn-block"
                      variant="primary" @click="updatePriceOrder()">UPDATE
            </b-button>
          </div>
        </div>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import {get, post} from "@/services/api";
import {orderUpdatePrice} from "@/services/endpoint.json";
import CurrencyPriceView from "@/components/common/CurrencyPriceView";

export default {
  name: "UpdatePrice",
  components: {CurrencyPriceView},
  props: ['price', 'order','pricePolicy','profitDistributor'],
  data: function () {
    return {
      loading: true,
      active: false,
      price_policy: null,
      commentsUpdatePrice: null,
      to_refund: null,
      client_can_complete: true
    }
  },
  computed: {
    final_price: function () {
      if (this.price_policy) {
        const response = Number(this.price * this.price_policy.percentage_on_request).toFixed(0)
        return Number(response);
      }
      const response = Number(this.price).toFixed(0)
      return Number(response);
    },

  },
  methods: {
    open() {
      const country_id = this.order.currencyDetails.country_id;
      const dealer_id = this.order.dealerId;
      this.to_refund = this.order.description.description.to_refund;

      get(`common/country-policy/${country_id}/${dealer_id}`, 'include_distributor', true)
        .then(({data}) => {
          this.price_policy = data;
          this.loading = false;
        })
      this.active = true;
    },
    async updatePriceOrder() {
      if (this.commentsUpdatePrice !== null) {
        this.$swal({
          showConfirmButton: true,
          showCancelButton: true,
          title: 'Are you sure you want to update the price of the order?',
          confirmButtonColor: '#d14343',
          cancelButtonColor: '#000000',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        })
          .then(async res => {
            if (res.isConfirmed) {
              const {data} = await post(orderUpdatePrice, {
                id: this.order.id,
                value: Number(this.final_price.toFixed(2)),
                comment: this.commentsUpdatePrice,
                price_policy: this.price_policy,
                pre_price: this.price,
                can_client_complete_order: this.client_can_complete
              });
              if (data.statusCode == 200) {
                this.order.price = this.final_price;
                this.order.currencyDetails.subtotal = this.final_price;
                this.showAlert('The price has been updated', 'success');
                this.active = false
                this.$emit('reload')
              }
            }
          });
      } else {
        this.showAlert('Comment is required', 'info');
      }
    },
    showAlert(msg, type) {
      this.$swal({
        title: (type === 'success') ? 'Success' : 'Error',
        html: msg,
        icon: (type === 'success') ? 'success' : 'error',
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
        confirmButtonText: 'OK',
        cancelButtonText: 'No',
      });
    },
    cancelUpdate() {
      this.$bvModal.hide('modal_update_price')
      this.$emit('reload')
    }
  }
}
</script>

<style scoped>

</style>
