const arrayDsg = [
  {
    type: '1',
    description: "TVS Stage 1 DSG software is a low budget solution which only includes raising necessary torque limiters and is especially suitable for really mild tuned vehicles.",
  },
  {
    type: '2',
    description: "TVS Stage 2 DSG software consists of various necessary software modifications for tuned vehicles improving your vehicle’s performance during launching, acceleration and up- and downshifting!.",
  },
  {
    type: '2.1',
    description: "TVS Stage 2+ DSG software is the best priced all-in-one package for your DSG transmission! It does include all drivability & life extender features, necessary performance modifications for tuned vehicles and additional options as well!",
  },
  {
    type: '3',
    description: "TVS Stage 3 DSG software is characterized as the strongest DSG software available on the market and especially suitable for high performance applications. In addition perfect drivability, durability and reliability will maintain."
  },
  {
    type: '4',
    description: "TVS Stage 4 DSG is custom made software for aftermarket DSG race clutches. We recommend purchasing this together with our in-house developed TVS DSG racing clutches."
  },
]

const arrayEcu = [
  {
    type: '1',
    description: 'TVS Stage 1 chiptuning is characterized as a mild increase in performance. TVS introduced this low budget solution to make TVS ECU software affordable for everyone!'
  },
  {
    type: '2',
    description: 'TVS Stage 2 chiptuning is characterized as maximum performance out of a stock engine setup within all safety limits. For gasoline engines performance numbers are based on RON 98 fuel, but is available for different fuel qualities.'
  },
  {
    type: '2.1',
    description: 'TVS Stage 2+ chiptuning is maximum performance software for a modified engine setup with stock turbo. Performance numbers may vary slightly depending on modifications and fuel grade.'
  },
  {
    type: '3',
    description: 'TVS Stage 3 chiptuning is custom made software for turbo- or supercharger upgrades! Due to our knowledge, experience and specialism in calibrating high power applications, TVS is able to deliver custom ECU software for almost every turbo- and supercharger upgrade.'
  },
  {
    type: '4',
    description: 'TVS Stage 4 chiptuning is custom made software for a big turbo- or supercharger upgrade with a heavily modified engine setup achieving performance numbers far above the factory ones!'
  },
]

const arrayCombos = [
  {
    id: 1,
    key: 'ecu1Dsg1',
    left: require('@/assets/images/stages/1.svg'),
    right: require('@/assets/images/stages/1.svg'),
    title: 'ECU STAGE I + DSG STAGE I',
    body: 'Enhance your vehicle’s performance at a mild level'
  },
  {
    id: 2,
    key: 'ecu22pDsg2p',
    left: require('@/assets/images/stages/2P.svg'),
    right: require('@/assets/images/stages/2P.svg'),
    title: 'ECU STAGE II+ DSG STAGE II+',
    body: 'Ensure an optimal performance ECU and DSG by including all drivability & life extender features within all safety limits'
  },
  {
    id: 3,
    key: 'ecu22pDsg3',
    left: require('@/assets/images/stages/2.svg'),
    right: require('@/assets/images/stages/3.svg'),
    title: 'ECU STAGE II+ DSG STAGE III',
    body: 'Get the most out of your turbo! Don’t hesitate and get the strongest DSG software available on the market as well.'
  },
  {
    id: 4,
    key: 'ecu3Dsg34',
    left: require('@/assets/images/stages/3.svg'),
    right: require('@/assets/images/stages/4.svg'),
    title: 'ECU STAGE III + DSG STAGE IV',
    body: 'Make a huge difference in drivability and performance by getting this combo.'
  },
  {
    id: 5,
    key: 'ecu4Dsg34',
    left: require('@/assets/images/stages/4.svg'),
    right: require('@/assets/images/stages/4.svg'),
    title: 'ECU STAGE IV + DSG STAGE IV',
    body: 'Feel satisfied knowing the ECU and DSG are properly running at their highest performance level.'
  },
];
export {arrayDsg, arrayEcu, arrayCombos}
