<template>
  <div ref="area" class="drop-area" :class="{'highlight': highlight}">
    <h6 class="m-0">Upload files</h6>
    <div class="form-group">
      <textarea class="form-control" v-model="dataForm.message" placeholder="Comment (optional)" rows="5"></textarea>
    </div>
    <div v-if="$userCan(['administrator','calibrator'])" class="form-group">
      <div class="d-flex justify-content-between">
        <h6>TVS data verification required?</h6>
        <div class="d-flex justify-content-end">
          <b-checkbox class="ml-3" v-model="dataForm.data_verification" :value="true" :unchecked-value="null">
            YES
          </b-checkbox>
          <b-checkbox class="ml-3" v-model="dataForm.data_verification" :value="false" :unchecked-value="null">
            NO
          </b-checkbox>
        </div>
      </div>
    </div>
    <div v-if="$userCan(['administrator','calibrator']) && dataForm.is_combo" class="form-group">
      <div class="d-flex justify-content-between">
        <h6>Type of tuning?</h6>
        <div class="d-flex justify-content-end">
          <b-checkbox class="ml-3" v-model="dataForm.is_ecu_completed" :disabled="completeStatus?completeStatus.is_ecu_completed: false">
            ECU
          </b-checkbox>
          <b-checkbox class="ml-3" v-model="dataForm.is_tcu_completed" :disabled="completeStatus?completeStatus.is_tcu_completed : false">
            DSG
          </b-checkbox>
        </div>
      </div>
    </div>
    <b-input-group-addon append class="pb-4">
      <b-btn ref="file" multiple="multiple" variant="btn-danger" type="file"
             @click="$refs.file.click()">
        <span class="material-icons">attachment</span>
      </b-btn>
      <div class="vue-tags-input form-control ti-focus disabled">
                <span v-for="(tag, i) in dataForm.answerExtraData"
                      class="badge badge-pill badge-secondary text-light text-uppercase mb-2 border"
                      style="color: white !important;">{{ tag.name }}
                  <button aria-label="Close" class="close" data-dismiss="alert" style="height: auto !important;"
                          type="button"
                          @click="dataForm.answerExtraData.splice(i , 1)">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </span>
      </div>
      <div>
      </div>
      <input ref="file" multiple style="display: none" type="file" @change="processFile($event.target.files)">
      <br>
    </b-input-group-addon>
    <span>Filename: Maximum 100 characters are allowed</span>
    <div class="text-right">
      <b-button class="btn btn-primary" type="submit" @click="request()">SEND FILE</b-button>
    </div>
    <div></div>
  </div>
</template>

<script>
import {post} from "@/services/api";
import {UserRole} from "@/utils/auth.roles";
import {isBoolean, serializeToDataForm} from "@/utils";
import {decrypt} from "@/helpers/encrypt";

export default {
  name: "FILE",
  props: ['currentId', 'EncryptOnline', "transfer", 'order', 'completeStatus'],
  computed: {
    id() {
      return this.currentId;
    },
    isEncryptOnline() {
      return this.EncryptOnline;
    },
  },
  watch: {
    completeStatus: {
      handler: function () {
        this.init();
      },
      deep: true,
    }
  },
  data: () => ({
    dataForm: {
      answerExtraData: [],
      message: '',
      data_verification: null,
      is_ecu_completed: false,
      is_tcu_completed: false,
      is_combo: false
    },
    highlight: false
  }),
  methods: {
    handleDrop(ev) {
      if (ev.dataTransfer.items) {
        const files = [];
        for (const item of ev.dataTransfer.items) {
          files.push(item.getAsFile())
        }
        this.processFile(files);
      } else {
        const files = [];
        for (const item of ev.dataTransfer.files) {
          files.push(item)
        }
        this.processFile(files)
      }
    },
    processFile(files) {
      this.$refs.file.type = 'text';
      this.$refs.file.type = 'file';

      for (const element of files) {
        if (element.size <= (1024 * 1024 * 10) && element.name.length <= 100) {
          this.dataForm.answerExtraData.push(element);
        } else {
          this.$swal({
            title: 'Error',
            text: this.$t('form.file_size_error_10')
              .toString(),
            icon: 'error',
            timer: 2500,
            confirmButtonColor: '#d14343',
            cancelButtonColor: '#000000',
          })
            .then();
        }
      }

    },
    async request() {
      if (!this.dataForm.answerExtraData.length) {
        await this.showAlert('You have not selected a file', 'error');
        return;
      }
      if (!isBoolean(this.dataForm.data_verification) && this.$userCan([UserRole.Calibrator, UserRole.Admin])) {
        await this.showAlert('TVS data verification is not defined.', 'error');
        return;
      }
      if (this.dataForm.is_combo && (!this.dataForm.is_tcu_completed && !this.dataForm.is_ecu_completed) && this.$userCan([UserRole.Calibrator, UserRole.Admin])) {
        await this.showAlert('Type of tuning is not defined.', 'error');
        return
      }

      if (this.dataForm.message.length >= 250) {
        await this.showAlert('Message. Maximum characters 250.', 'error');
        return;
      }

      if (this.$userCan([UserRole.Calibrator, UserRole.Admin])) {
        this.$swal({
          showConfirmButton: true,
          showCancelButton: true,
          title: 'Confirm',
          icon: 'info',
          text: 'Do you want to send the file to the client?',
          confirmButtonColor: "#d14343",
          cancelButtonColor: "#000000",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            this.processCompleteRequest().then()
          }
        });
      } else {
        this.processCompleteRequest().then()
      }
    },
    async processCompleteRequest() {
      this.dataForm.viewed = this.processViewedInformation()
      this.$store.commit('loaderManager', true);
      const formData = serializeToDataForm(this.dataForm);
      post(`order-software/add-answer/${this.id}`, formData, true)
        .then(() => {
          this.showAlert('Message sent!', 'success');
          this.$emit('reloadPage')
          this.clearDataForm();
        })
        .finally(() => this.$store.commit('loaderManager', false));
    },
    clearDataForm() {
      this.dataForm = {
        answerExtraData: [],
        message: '',
        data_verification: null,
        type_completed: null,
        is_combo: this.order.type === 'ECU + DSG'
      };
    },
    async showAlert(msg, type) {
      await this.$swal({
        title: (type === 'success') ? 'Success' : 'Error',
        text: msg,
        icon: (type === 'success') ? 'success' : 'error',
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "OK",
        cancelButtonText: "No",
      });
    },
    processViewedInformation() {
      const store = localStorage.getItem("admin-type");
      const sessionRol = JSON.parse(decrypt(store))
      const obj = {
        id: 1,
        administrator: 1,
        calibrator: 1,
        distributor: 1,
        dealer: 1,
        subdealer: 1,
        owner: 1,
      }
      obj[sessionRol[0]] = 0
      return obj
    },
    init() {
      this.dataForm.is_combo = this.order.type === 'ECU + DSG';
      if (this.completeStatus) {
        this.dataForm.is_ecu_completed = this.completeStatus.is_ecu_completed;
        this.dataForm.is_tcu_completed = this.completeStatus.is_tcu_completed;
        this.$forceUpdate()
      }
    }
  },
  mounted() {
    const area = this.$refs.area;
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      area.addEventListener(eventName, preventDefaults, false)
    })

    function preventDefaults(e) {
      e.preventDefault()
      e.stopPropagation()
    }

    ;['dragenter', 'dragover'].forEach(eventName => {
      area.addEventListener(eventName, () => this.highlight = true, false)
    })

    ;['dragleave', 'drop'].forEach(eventName => {
      area.addEventListener(eventName, () => this.highlight = false, false)
    })
    area.addEventListener('drop', this.handleDrop, false);
    this.init();
  },
}
</script>

<style scoped lang="scss">
.drop-area {
  position: relative;
  padding: 1em 0.5em;
}

.highlight {
  background-color: rgba(214, 208, 208, 0.29);
  position: relative;
  //border: 1px solid #cccccc;

  .form-control {
    background: transparent;
  }

  &:after {
    width: 100%;
    position: absolute;
    left: 9%;
    right: 0;
    bottom: 26.5%;
    color: #9d9d9d;
    font-size: 17px;
    content: "Drop file(s)";
    text-shadow: 0 0 10px #0b0b0b;
    opacity: 0.8;
  }

  img {
    opacity: 0.1;
  }
}
</style>
