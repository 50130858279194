<template>
  <div style="width: 100%;" class="table-responsive">
    <table class="table table-hover table-sm table-bordered">
      <thead>
      <tr>
        <th colspan="2">VIN information</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>MAKE</td>
        <td>{{ info.make }}</td>
      </tr>
      <tr>
        <td>MODEL</td>
        <td>{{ info.model_description }}</td>
      </tr>
      <tr>
        <td>ENGINE CODE</td>
        <td>{{ info.engine_code }}</td>
      </tr>
      <tr>
        <td>DSG</td>
        <td>{{ info.dsg_code }}</td>
      </tr>
      <tr>
        <td>ECU</td>
        <td>{{ info.ecu_code }}</td>
      </tr>
      <tr>
        <td>TRANSMISSION CODE</td>
        <td>{{ info.transmission_code }}</td>
      </tr>
      <tr>
        <td>MODEL YEAR</td>
        <td>{{ info.model_year }}</td>
      </tr>
      </tbody>
    </table>
    <div v-if="!info">
      <div v-if="isSearch" class="d-flex">
        <div class="loader">
        </div>
        <h4 class="text-black-50 ml-3">Wait for response ...</h4>
      </div>
      <div v-if="isSearch === false">
        <b-button class="pull-right" size="sm" variant="primary" @click="searchVin">Search</b-button>
        <p>Not found</p>
      </div>
    </div>
  </div>
</template>

<script>

import { post } from '@/services/api';
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: 'VIN',
  props: {
    info: { default: {} },
    vin: String
  },
  mixins:[show_alert_mixin],
  data: ()=>({
    isSearch: false
  }),
  methods: {
    searchVin() {
      try {
        this.isSearch = true;
        post('site/find-by-vin', { vin: this.vin }).then(() => this.waitResponse()).catch(e=>{
          this.isSearch = false
          this.showAlertNotification('We did not find VIN information','info')
        });
      }catch (e) {
        this.isSearch = false
        this.showAlertNotification('We did not find VIN information','info')
      }
    },
    waitResponse() {
      try {
        this.sockets.subscribe(this.vin, (resp) => {
          this.isSearch = false;
          let response = JSON.parse(resp);
          if (response.statusCode === 200) {
            this.info = response.body;
          } else {
            this.isSearch = false;
            this.showAlertNotification('We did not find VIN information','info')
          }
        });
      }catch (e) {
        this.isSearch = false;
        this.showAlertNotification('We did not find VIN information','info')
      }
    },
  }
};

</script>

<style scoped>

</style>

