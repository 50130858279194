<template>
  <div v-if="transfer">
    <ul class="list-group mb-4">
      <li class="list-group-item d-flex justify-content-between">
        <span>Transferred on:</span>
        <span>{{ transfer.date | localeDate }}</span>
      </li>
    </ul>
    <b-row class="mb-2">
      <b-col>
        <ul class="list-group">
          <li class="list-group-item">
            {{ transfer.fromUser.name }}
          </li>
        </ul>
      </b-col>
      <b-col cols="1" class="d-flex justify-content-center">
        <i class="material-icons" style="font-size: 40px">arrow_forward</i>
      </b-col>
      <b-col>
        <ul class="list-group">
          <li class="list-group-item">
            {{ transfer.toUser.name }}
          </li>
        </ul>
      </b-col>
    </b-row>
    <div class="form-group btn-group text-right">
      <b-button variant="danger" v-if="transfer.toUser.id === $store.getters.getUserId" @click="rejectTransfer">
        REJECT TRANSFER
      </b-button>
      <b-button variant="dark" v-if="transfer.toUser.id === $store.getters.getUserId || $userCan(['administrator'])"
                @click="acceptTransfer">
        ACCEPT TRANSFER
      </b-button>
    </div>
  </div>
  <div v-else>
    <div class="form-group">
      <label>Select Dealer</label>
      <b-select v-model="model.toUserId">
        <option :value="null">Select user</option>
        <option v-for="user of subUsers" :value="user.id">{{ user.fullName }}</option>
      </b-select>
    </div>
    <div class="form-group text-right">
      <b-button variant="primary" @click="save">Transfer to this user</b-button>
    </div>
  </div>
</template>

<script>
import {post} from '@/services/api';
import {mapMutations, mapState} from 'vuex';

export default {
  name: 'TransferOrder',
  props: {
    order: {
      type: Object,
      required: true
    },
    transfer: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    users: [],
    model: {
      toUserId: null
    },
  }),
  computed: mapState('buySale', ['subUsers']),
  mounted() {
    this.$store.dispatch('buySale/loadUserList');
  },
  methods: {
    ...mapMutations(['loaderManager']),
    save() {
      if (this.model.toUserId) {
        this.$swal({
          title: 'Are you sure ?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#d14343',
          cancelButtonColor: '#000000',
        })
          .then(value => {
            if (value.isConfirmed) {
              post(`order-software/transfer/${this.order.id}`, this.model, true)
                .then(({data}) => {
                  this.transfer = data;
                  this.$emit('loadData');
                  this.$swal({
                    title: 'Success',
                    icon: 'success',
                    showConfirmButton: false,
                    showCancelButton: false,
                    timer: 2000,
                    confirmButtonColor: '#d14343',
                    cancelButtonColor: '#000000',
                  });
                }).catch(error => {
                this.$swal({
                  title: 'Error',
                  text: error.response.data.message,
                  icon: 'error',
                  showConfirmButton: false,
                  showCancelButton: false,
                  timer: 3500,
                  confirmButtonColor: '#d14343',
                  cancelButtonColor: '#000000',
                });
              })
            }
          });

      } else {
        this.$swal({
          title: 'Select a dealer',
          icon: 'warning',
          timer: 1500,
          showConfirmButton: false,
          confirmButtonColor: '#d14343',
          cancelButtonColor: '#000000',
        })
      }
    },
    acceptTransfer() {
      this.$swal({
        title: 'Are you sure ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
      })
        .then(value => {
          if (value.isConfirmed) {
            post(`order-software/accept-transfer/${this.transfer._id}`)
              .then(() => {
                this.transfer = null;
              });
          }
        });
    },
    rejectTransfer() {
      try {
        this.load
        this.$swal({
          title: 'Are you sure ?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#d14343',
          cancelButtonColor: '#000000',
        })
          .then(async value => {
            if (value.isConfirmed) {
              await post(`order-software/reject-transfer/${this.transfer._id}`)
              this.$router.push({name: 'dashboard'});
            }
          });
      } catch (e) {
        this.$router.push({name: 'dashboard'}).then().catch()
      }
    }
  }
};
</script>

<style scoped>

</style>
