<template>
  <div>
    <table class="table table-bordered" style="width: 100%">
      <thead>
      <tr>
        <th colspan="5">VIN History</th>
      </tr>
      <tr>
        <th>Order Number</th>
        <th>Price <small>w/o extra fee</small></th>
        <th>Type</th>
        <th>Status</th>
        <th>Date</th>
        <th>Option</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in orderList" :key="index" :class="{'table-success': item.id == decryptId}">
        <td>{{ item.order_id }}</td>
        <td>
          <credits-view v-if="item.price != 9999" :amount="item.price" :currency-details="item.currencyDetails"/>
          <span v-else>On request</span>
        </td>
        <td>{{ item.type }}</td>
        <td>{{ item.status|orderStatus }}</td>
        <td>{{ item.createdAt |localeDate }}</td>
        <td>
          <a v-tippy="{ placement : 'top',  arrow: true }" :content="'View detail'" role="button" @click="redirectTo(item.id)">
            <i class="material-icons text-black">visibility</i>
          </a>
        </td>
      </tr>
      </tbody>
    </table>
    <template v-if="previousOrders.length && $userCan(['administrator','support','calibrator'])">
      <table class="table table-bordered mb-0">
        <tr>
          <th class="border-bottom-0">Previous platform orders</th>
        </tr>
      </table>
      <div v-for="order of previousOrders">
        <div class="table-responsive">
          <table class="table table-bordered table-sm m-0 border-bottom-0">
            <tr>
              <th>VIN</th>
              <td>{{ order.VIN }}</td>
              <th>Credits</th>
              <td>{{ order.price }}</td>
            </tr>
          </table>
        </div>
        <b-table :items="[order.all_data]" responsive bordered small hover></b-table>
      </div>
    </template>
  </div>
</template>

<script>

import CreditsView from '@/components/common/CreditsView';
import {post} from "@/services/api";
import {decrypt, encrypt} from "@/helpers/encrypt";

export default {
  name: 'RelatedHistory',
  components: {CreditsView},
  props: ['orderListInfo', 'vin'],
  computed: {
    orderList() {
      return this.orderListInfo;
    },
  },
  data: () => ({
    selectedClass: 'selected',
    previousOrders: [],
    decryptId: null,
  }),
  methods: {
    async redirectTo(id) {
      const cryp = encrypt(JSON.stringify({id: id}))
      this.$router.push({name: 'order-detail', params: {id: cryp}})
      this.decryptId = id
    },
    find() {
      post(`previous-orders/find/${this.vin}`, null, true)
        .then(({data}) => this.previousOrders = data)
    }
  },
  watch: {
    orderList: function () {
      this.find()
    }
  },
  mounted() {
    this.find()
    this.decryptId = JSON.parse(decrypt(this.$route.params.id)).id
  }
};

</script>

<style scoped>
.border-bottom-0 tr td, .border-bottom-0 tr th {
  border-bottom: 0;
}

.selected {
  background: #d14343;
}
</style>

