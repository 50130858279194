import Vue from 'vue';
import {get, post} from '@/services/api';
import {
  assingCalibrator,
  orderDetail,
  orderSoftwareUpdateOnlyStatus,
  updateOrderSoftware,
  warrantsCreate
} from '@/services/endpoint.json';
import {userCan} from '@/utils';
import {decrypt} from '@/helpers/encrypt';
import {mapGetters, mapMutations, mapState} from 'vuex';
import Price from './Price.vue';
import RelatedHistory from './RelatedHistory.vue';
import VIN from './VIN.vue';
import FILE from './File.vue';
import FileHistory from './FileHistory.vue';
import SupportHistory from './SupportHistory.vue';
import Logs from './Logs.vue';
import TableOrderBack from './TableOrder.vue';
import OptionsEditor from '@/components/SoftwareOrders/OptionsEditor';
import {UserRole} from '@/utils/auth.roles';
import TransferOrder from '@/views/dashboard/order-detail/TransferOrder';
import CreditsView from '@/components/common/CreditsView';
import CurrencyPriceView from '@/components/common/CurrencyPriceView';
import TunedFileView from "@/components/SoftwareOrders/TunedFileView";
import ChangeVehicle from "@/views/dashboard/order-detail/ChangeVehicle";
import UpdatePrice from "@/views/dashboard/order-detail/components/UpdatePrice";
import {ORDER_STATUS} from "@/constants";

export default Vue.extend({
  name: 'OrderDetail',
  components: {
    SupportChat: () => import('@/views/dashboard/support/support-chat/index.vue'),
    CreateModal: () => import('@/views/dashboard/support/components/create-modal'),
    CreditsView,
    CurrencyPriceView,
    TransferOrder,
    Price,
    RelatedHistory,
    VIN,
    FILE,
    FileHistory,
    SupportHistory,
    OptionsEditor,
    Logs,
    TableOrderBack,
    TunedFileView,
    ChangeVehicle,
    UpdatePrice,
    UpdateVin: () => import('@/views/dashboard/order-detail/components/UpdateVin.vue'),
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      this.nameCollapseSections.push({id: collapseId, status: isJustShown})
      const element = document.getElementById(`${collapseId}-icon`)
      if (element !== null && element !== undefined) {
        element.classList.remove('fa-plus')
        if (isJustShown) {
          element.classList.remove('fa-plus')
          element.classList.add("fa-window-minimize");
        } else {
          element.classList.remove('fa-window-minimize')
          element.classList.add("fa-plus");
        }
      }
    })
  },
  data: () => ({
    dateNow: '',
    name: '',
    lastName: '',
    country: '',
    email: '',
    phone: '',
    order: null,
    infoVin: {},
    cart: null,
    id: null,
    dataDetail: null,
    histories: [],
    optionStatus: null,
    optionSelectedCalibrator: null,
    orderFiles: [],
    dataVehicle: null,
    orderStatus: ORDER_STATUS(),
    calibrators: [],
    isSearch: false,
    isEncryptOnline: false,
    support: false,
    price: 0,
    orderList: [],
    supportMessage: [],
    currentUserId: null,
    processWarranty: {
      comment: '',
      price: 0,
      userId: null,
      modelId: null,
    },
    balance: 0,
    logs: [],
    itemsEdited: [],
    sofware: [],
    sofwareMandatory: [],
    hardware: [],
    hardwareMandatory: [],
    dsgHardwareOptions: [],
    dsgOptions: [],//software optionals dsg
    dsgHardwareXoptions: [], //hardware required dsg
    preSelectedDsgOptions: [], //software required dsg
    transfer: null,
    userGroupPrice: null,
    tabActive: false,
    tabTransferActive: false,
    tabFileActive: false,
    tabLogActive: false,
    showBtnAssign: true,
    warrantyFee: [],
    warrantyAmount: 0,
    showBackPage: false,
    nameCollapseSections: [],
    expandCollapse: {
      'collapse-hardware-additional': false,
      'collapse-software-additional': false,
      'collapse-software-mandatory': false,
      'collapse-hardware-mandatory': false,
      'collapse-dsg-additional': false,
      'collapse-dsg-hardware-options': false,
      'collapse-dsg-mandatory': false,
      'collapse-dsg-x-mandatory': false,
    },
    tuned_files: [],
    personal_flasher: null,
    currentViewed: null,
    updatedViewed: null,
    willDeductCredits: true,
    profitDistributor: false,
    completeStatus: null,
    pricePolicy: null
  }),
  beforeUpdate() {
    this.checkUrlId()
  },
  async created() {
    try {
      this.id = this.$route.params.id
      this.checkUrlId()
      this.currentUserId = decrypt(localStorage.getItem('admin-id'));
      await this.load();
      this.sockets.subscribe(`updateOrderDetail:${this.id}`, () => {
        // this.$refs.createSupport.close();
        this.load()
      });
      this.getCalibrators();
    } catch (e) {
      this.$router.push("/error")
    }
  },
  computed: {
    ...mapGetters('geo', {
      getCountry: 'country',
      priceFor: 'priceFor',
      currencyToUser: 'currencyToUser',
      getCurrencyByCountry: 'getCurrencyByCountry',
      numberFilter: 'numberFilter'
    }),
    ...mapState('buySale', {
      haveSubUsers: state => state.information.haveChildren
    }),
    priceFinal() {
      let finalPrice = this.order.price;
      if (this.personal_flasher) {
        finalPrice = (this.personal_flasher && this.order.isPersonalFlasher) ? (finalPrice + (this.personal_flasher.percent * this.order.price)) : finalPrice
      } else {
        finalPrice = (this.userGroupPrice && this.order.isPersonalFlasher) ? (finalPrice + (this.userGroupPrice.overrunPf * this.order.price)) : finalPrice
      }
      if (this.cart.description.to_refund && this.cart.description.to_refund.upgrade_fee) {
        finalPrice += this.cart.description.to_refund.upgrade_fee
      }
      finalPrice = (this.warrantyAmount > 0) ? (finalPrice + this.warrantyAmount) : finalPrice
      return finalPrice
    },
    priceFinalNoWarranty() { //without warranty
      let finalPrice = this.order.price;
      if (this.personal_flasher) {
        finalPrice = (this.personal_flasher && this.order.isPersonalFlasher) ? (finalPrice + (this.personal_flasher.percent * this.order.price)) : finalPrice
      } else {
        finalPrice = (this.userGroupPrice && this.order.isPersonalFlasher) ? (finalPrice + (this.userGroupPrice.overrunPf * this.order.price)) : finalPrice
      }
      if (this.cart.description.to_refund && this.cart.description.to_refund.upgrade_fee) {
        finalPrice += this.cart.description.to_refund.upgrade_fee
      }
      return finalPrice
    },
    PFPriceFee() {
      if (this.userGroupPrice && this.order.isPersonalFlasher) {
        return this.order.price * this.userGroupPrice.overrunPf;
      }
      return 0;
    },
    processReadInformation() {
      if (this.order.viewed) {
        const current = JSON.parse(this.order.viewed)
        const store = localStorage.getItem("admin-type");
        const sessionRol = JSON.parse(decrypt(store))
        const obj = {
          id: this.order.id,
          administrator: current.administrator,
          calibrator: current.calibrator,
          distributor: current.distributor,
          dealer: current.dealer,
          subdealer: current.subdealer,
          owner: current.owner,
        }
        this.currentViewed = obj;
        obj[sessionRol[0]] = 0
        this.updatedViewed = obj;
        return obj
      } else {
        return {
          id: this.order.id,
          administrator: 0,
          calibrator: 0,
          distributor: 0,
          dealer: 0,
          subdealer: 0,
          owner: 0,
        }
      }
    }
  },
  methods: {
    ...mapMutations(['loaderManager']),
    showInRed(location) {
      return ['hardware', 'sofware', 'dsgOptions', 'dsgHardwareOptions'].includes(location)
    },
    checkUrlId() {
      if (typeof this.id === 'string') {
        const dec = decrypt(this.id)
        this.id = JSON.parse(dec).id
      }
    },
    print() {
      this.showBackPage = true;
      Object.keys(this.expandCollapse).forEach(key => {
        this.expandCollapse[key] = true
      })
      setTimeout(() => {
        window.print();
      }, 500)
    },
    async load() {
      try {
        this.checkUrlId()
        let {data} = await post(orderDetail, {id: this.id}, true);
        this.pricePolicy = data.pricePolicy
        this.order = data.order;
        this.orderList = data.orderList;
        this.supportMessage = data.support;
        this.order.vehicle = JSON.parse(this.order.vehicle);
        this.order.description = JSON.parse(this.order.description);
        if (this.order.description.description.vinCheck) {
          this.order.description.description.vinCheck.verified = this.order.description.description.vinCheck.verified ? this.order.description.description.vinCheck.verified : false
        }
        if (this.order.calibratorIds && this.order.calibratorIds.length) {
          this.order.calibratorId = this.order.calibratorIds[this.order.calibratorIds.length - 1]
        } else {
          this.order.calibratorId = null;
        }
        this.cart = this.order.description;
        this.id = data.order.id;
        this.dateNow = data.order.createdAt;
        this.optionStatus = data.order.status;
        this.optionSelectedCalibrator = data.order.calibratorId;
        this.histories = data.histories.reverse();
        this.infoVin = data.infoVin;
        this.price = this.order.price !== 9999 ? this.order.price : 0;
        this.balance = data.balance;
        this.warrantyFee = data.extraFee;
        this.tuned_files = data.files_tuned ? data.files_tuned : [];
        this.personal_flasher = data.personal_flasher;
        this.willDeductCredits = data.willDeductCredits;
        this.completeStatus = data.completeStatus;

        const disableOption = (el) => {
          if (el.value === 'Rejected') {
            return this.willDeductCredits
          }
          if (el.value === 'Completed') {
            return this.completeStatus && this.completeStatus.is_ecu_completed && this.completeStatus.is_tcu_completed
          }
          return el.showInSelect
        }
        this.orderStatus = this.orderStatus.map(el => ({
          ...el,
          showInSelect: disableOption(el)
        }))
        this.profitDistributor = data.profitDistributor;

        if (userCan([UserRole.Calibrator])) {
          if (this.optionSelectedCalibrator === null) {
            this.showBtnAssign = true;
            this.optionSelectedCalibrator = decrypt(localStorage.getItem('admin-id'));
          }
        }
        if (this.tuned_files.length && this.order.dealerId == this.$store.getters.getUserId) {
          this.tabFileActive = true
        }
        await this.checkIfSupport();
        this.logs = data.logs.map(item => {
          item.description = JSON.parse(item.description);
          return item;
        });
        this.tabLogActive = this.completeStatus != null && this.completeStatus.is_ecu_completed && this.completeStatus.is_tcu_completed;
        if (this.$route.query['open-chat'] !== undefined || this.tabActive) {
          this.tabLogActive = false;
          this.openChat();
        }

        this.sofware = this.cart.item.filter(x => x.location.toString() === 'sofware');
        this.sofwareMandatory = this.cart.item.filter(x => x.location.toString() === 'sofwareMandatory');
        this.hardware = this.cart.item.filter(x => x.location.toString() === 'hardware');
        this.hardwareMandatory = this.cart.item.filter(x => x.location.toString() === 'hardwareMandatory');
        this.dsgHardwareOptions = this.cart.item.filter(x => x.location.toString() === 'dsgHardwareOptions');
        this.dsgOptions = this.cart.item.filter(x => x.location.toString() === 'dsgOptions');
        this.dsgHardwareXoptions = this.cart.item.filter(x => x.location.toString() === 'dsgHardwareXoptions');
        this.preSelectedDsgOptions = this.cart.item.filter(x => x.location.toString() === 'preSelectedDsgOptions');

        if (this.order.isPersonalFlasher && !this.personal_flasher) {
          this.loadPersonalFlasherPrice();
        } else if (this.personal_flasher) {
          this.userGroupPrice = {overrunPf: this.personal_flasher.percent};
        }
        this.checkTansfer();
        this.calculateExtraFee()
        setTimeout(() => {
          this.markRead().then();
        }, 1000)
      } catch (e) {
        this.$router.push({name: 'dashboard'});
      }
    },
    async showAlert(msg, type) {
      await this.$swal({
        title: (type === 'success') ? 'Success' : 'Error',
        html: msg,
        icon: (type === 'success') ? 'success' : 'error',
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
        confirmButtonText: 'OK',
        cancelButtonText: 'No',
      });
    },
    async assignCalibrator(refresh = false) {
      if (this.optionSelectedCalibrator != null && this.optionSelectedCalibrator !== '') {
        const {data} = await post(assingCalibrator, {
          id: this.id,
          calibratorId: this.optionSelectedCalibrator,
        }, true);
        if (Number(data.statusCode) === Number(200)) {
          await this.showAlert('Calibrator assigned!', 'success');
        }
      }
      if (refresh) {
        await this.load();
      }
    },
    async updateStatus() {
      if (!['Cancel', 'Rejected'].includes(this.optionStatus) && this.order.client.type.includes('support')) {
        await this.showAlert('This order can only be Rejected because the owner is a support role');
        return;
      }
      if (this.order.status.toString() !== 'Completed') {
        if (this.optionStatus.toString() !== 'Cancel' && this.optionStatus.toString() !== 'Warranty') {
          if (this.optionStatus.toString() === 'Completed') {
            if (this.optionSelectedCalibrator !== null && this.optionSelectedCalibrator !== '' && this.order.calibratorIds.length > 0) {
              if (!this.order.isPersonalFlasher || (this.PFPriceFee > 0)) {
                let message = {title: `Do you want to complete the order and deduct ${this.numberFilter(this.priceFinalNoWarranty)} credits from the clients balance?`}
                if (!this.willDeductCredits) {
                  message = {
                    title: `Do you want to complete the order?`,
                    html: `This order was already completed. Credits won't be deducted again.`
                  }
                }
                this.$swal({
                  showCancelButton: true,
                  showConfirmButton: true,
                  ...message,
                  confirmButtonColor: '#d14343',
                  cancelButtonColor: '#000000',
                  cancelButtonText: 'No',
                  confirmButtonText: 'Yes',
                })
                  .then(async res => {
                    if (res.isConfirmed) {
                      await this.stepTwoUpdateOrder(true, false);
                    } else {
                      this.optionStatus = this.order.status;
                    }
                  });
              } else {
                await this.showAlert('You must configure the personal flasher fee, or remove it for this order.', 'error');
                this.optionStatus = this.order.status;
              }
            } else {
              await this.showAlert('Please assign a calibrator.', 'error');
            }
          } else {
            await this.stepTwoUpdateOrder(false, false);
          }
        } else {
          await this.showAlert('The order must be completed for you to cancel / warranty.', 'error');
          this.optionStatus = this.order.status;
        }
      } else {
        if (this.optionStatus.toString() !== 'Completed') {
          if (this.optionStatus.toString() === 'Cancel') {
            await this.stepOneUpdateOrder();
          } else if (this.optionStatus.toString() === 'Warranty') {
            this.$swal({
              showCancelButton: true,
              showConfirmButton: true,
              title: 'Are you sure you want to place the order on warranty?',
              confirmButtonColor: '#d14343',
              cancelButtonColor: '#000000',
              cancelButtonText: 'No',
              confirmButtonText: 'Yes',
            })
              .then(async res => {
                if (res.isConfirmed) {
                  await this.stepTwoUpdateOrder(false, false);
                }
              });
          } else {
            await this.showAlert('Only allowed to cancel / warranty', 'error');
            this.optionStatus = this.order.status;
          }
        } else {
          await this.showAlert('The current status of the order is completed.', 'error');
          this.optionStatus = this.order.status;
        }
      }
    },
    async stepOneUpdateOrder() {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        title: `The order had been completed and paid. Do you want to return ${this.numberFilter(this.priceFinalNoWarranty)} credits to the customer?`,
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async res => {
        if (res.isConfirmed) {
          await this.stepTwoUpdateOrder(false, true);
        }
      });
    },
    async stepTwoUpdateOrder(deduct, refund) {
      const {data} = await post(updateOrderSoftware, {
        id: this.id,
        status: this.optionStatus,
        refund,
        deduct,
        from: this.currentUserId,
        to: this.order.user_id,
        value: this.order.price,
        before: this.order.status,
      }, true);
      if (Number(data.statusCode) === Number(200)) {
        this.order.status = this.optionStatus;
        await this.showAlert('Order status updated', 'success');
      } else if (Number(data.statusCode) === Number(422)) {
        if (this.order.status !== 'Waiting on feedback')
          this.confirmationUpdateOrderTunedSupplied()
        else {
          const msg = `<div style="width: 100%;">Customer's balance insufficient.</div> The platform will attempt to complete the order when the client has enough credits.</div>`
          await this.showAlert(msg, 'error');
        }
        this.optionStatus = this.order.status;
      }
    },
    confirmationUpdateOrderTunedSupplied() { // el usuario no tiene balance. el sistema intentara diariamente cobrar la orden y pasarla a completa
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        html: `<div style="width: 100%;">Customer's balance insufficient.</div>\n` +
          '<div style="width: 100%;">Do you want to update the status of the order to Tune Supplied? The platform will attempt to complete the order when client has enough credits.</div>',
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async res => {
        if (res.isConfirmed) {
          await post(orderSoftwareUpdateOnlyStatus, {
            id: this.order.id,
            status: 'Waiting on feedback'
          }, true);
          await this.load();
          await this.showAlert('Order status updated', 'success');
        }
      });
    },
    getCalibrators() {
      get('user/administrator,calibrator', null, true)
        .then(res => this.calibrators = res.data.map(el => {
          return {
            value: el.id,
            text: el.fullName,
          };
        }));
    },
    async checkIfSupport() {
      await post('support/check-support', {
        order_id: this.id,
        model: 1
      })
        .then(({data}) => {
          if (typeof data === 'object') {
            this.support = data;
          } else {
            this.support = null;
          }
          return true;
        });
    },
    onTicketCreated(e) {
      this.support = e;
      this.$refs.chat.loadSupport(this.support.id);
    },
    openChat() {
      this.tabActive = true;
      if (this.support) {
        if (!!this.$refs.chat && !this.$refs.chat.support) {
          this.$refs.chat.loadSupport(this.support.id);
        }
      } else if (this.$refs.createSupport) {
        if (this.order.dealerId !== this.$store.getters.getUserId) {
          this.$refs.createSupport.model.clientUserId = this.order.dealerId;
        }
        this.$refs.createSupport.model.vin = this.order.VIN;
        this.$refs.createSupport.setCategory(this.$store
          .getters['support/getCategoryById'](1), this.order.id, 'OrderSoftwareEntity');
        this.$refs.createSupport.open();
      }
    },
    onVehicleChange(data) {
      if (data.update_price) {
        this.load().then(() => {
          this.openPriceChange(data.price)
        })
      }
    },
    openPriceChange(value = null) {
      if (value) {
        this.price = value
      }
      this.$refs.update_price.open(this.order.currencyDetails.country_id, this.order.dealerId)
    },
    async redirectTo(id) {
      this.id = id;
      await this.load();
    },
    async applyFee() {
      if (this.processWarranty.comment !== null && this.processWarranty.comment !== '') {
        if (Number(this.processWarranty.price) >= 0 && this.processWarranty.price !== null && this.processWarranty.price !== '') {
          if (Number(this.balance) >= Number(this.processWarranty.price)) {
            this.$swal({
              showConfirmButton: true,
              showCancelButton: true,
              title: 'Are you sure?',
              confirmButtonColor: '#d14343',
              cancelButtonColor: '#000000',
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
            })
              .then(async res => {
                if (res.isConfirmed) {
                  this.processWarranty.userId = this.order.dealerId;
                  this.processWarranty.modelId = this.order.id;
                  const {data} = await post(warrantsCreate, this.processWarranty, true);
                  if (Number(data.statusCode) === 200) {
                    await this.load();
                    await this.showAlert('', 'success');
                    this.clearFormWarranty()
                    this.$swal({
                      showConfirmButton: true,
                      showCancelButton: true,
                      title: 'Do you want to update the status of the order to complete?',
                      confirmButtonColor: '#d14343',
                      cancelButtonColor: '#000000',
                      confirmButtonText: 'Yes',
                      cancelButtonText: 'No',
                    })
                      .then(async res => {
                        if (res.isConfirmed) {
                          this.order.status = 'Completed';
                          this.optionStatus = 'Completed';
                          await post(orderSoftwareUpdateOnlyStatus, {
                            id: this.order.id,
                            status: this.order.status
                          }, true);
                          this.clearFormWarranty()
                          await this.showAlert('Order status updated', 'success');
                        }
                      });
                  }
                }
              });
          } else {
            await this.showAlert('User does not have enough credits balance', 'error');
          }
        } else {
          await this.showAlert('Wrong value extra fee. Min value is zero.', 'error');
        }
      } else {
        await this.showAlert('Comment is required', 'error');
      }
    },
    clearFormWarranty() {
      this.processWarranty = {
        comment: '',
        price: 0,
        userId: null,
        modelId: null,
      };
    },
    editOrder() {
      this.$store.commit('orderSoftwareFile/showDsgConversionAndFlex', true);
      this.$refs.modalEdit.show();
      this.$nextTick(() => {
        this.$refs.editor.loadVehicle(this.order.vehicle.id, this.order.vehicle.dsg_tvs)
          .then(() => {
            this.$refs.editor.setOrder(this.order);
          });
      });
    },
    updateOptions() {
      if (this.$refs.editor.isEdited) {
        post(`order-software/update-options/${this.id}`, {
          oldItems: this.order.description.item,
          newItems: this.itemsEdited
        }, true)
          .then(async () => {
            await this.showAlert('Changes saved!', 'success');
            this.$store.commit('orderSoftwareFile/showDsgConversionAndFlex', false);
            await this.load();
          });
      }
    },
    loadPersonalFlasherPrice() {
      get(`credit-price-config/price-for-personal-flasher/${this.order.dealerId}`)
        .then(({data}) => {
          this.userGroupPrice = data;
        });
    },
    switchPersonalFlasher() {
      if (!this.userGroupPrice) {
        this.loadPersonalFlasherPrice();
      }
      post(`order-software/update-pf/${this.id}`, {isPersonalFlasher: this.order.isPersonalFlasher})
        .then(() => {
          this.$swal({
            title: 'Success',
            icon: 'success',
            showConfirmButton: false,
            showCancelButton: false,
            timer: 2000
          });
        });
    },
    checkTansfer() {
      get(`order-software/find-transfer/${this.order.id}`, null, true)
        .then(({data}) => {
          this.transfer = data;
          if (this.transfer) {
            this.$nextTick(() => this.tabTransferActive = true);
          }
        });
    },
    getColor(status) {
      const obj = this.orderStatus.find(x => x.value === status)
      return obj ? obj.color : 'black'
    },
    calculateExtraFee() {
      this.warrantyAmount = this.warrantyFee.reduce((previousValue, currentValue) => previousValue + ((currentValue.amount * -1)), 0)
    },
    async markRead() {
      await post('order-software/watch', this.processReadInformation, true)
    },
    async showMessage(text, icon) {
      await this.$swal({
        html: text,
        icon: icon,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonColor: '#d14343',
      });
    },
    async completedOrderZero() {
      await post(orderSoftwareUpdateOnlyStatus, {
        id: this.order.id,
        status: this.optionStatus,
        createTransaction: true,
      }, true);
    },
    async resendCertificate() {
      this.loaderManager(true);
      await post("order-software/resend-certificate", {id: this.order.id}, true)
      this.loaderManager(false);
      this.showAlert("success", "success")
    },

    openEditVehicle() {
      this.$refs.change_vehicle.open(this.order)
    },
    openEditVin() {
      this.$refs.updateVin.open(this.order)
    }
  },
  watch: {
    '$route.params'() {
      this.id = this.$route.params.id;
      this.load().then(() => {
        this.$nextTick(() => {
          this.$forceUpdate();
        });
      })
    },
    support: {
      deep: true,
      handler: function (newVal, preVal) {
        if (this.support) {
          this.$refs.chat.loadSupport(this.support.id);
        } else {
          this.$refs.chat.clear();
        }
      }
    }
  },
  destroyed() {
    this.sockets.unsubscribe(`updateOrderDetail:${this.id}`);
  }
});
